import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';

import { arrayToQueryString } from '@web/utils/functions';
import { getMicroserviceUrl } from '@web/utils/@microservices/functions';

import { MicroserviceNames } from '@web/utils/@microservices/types';

type ORDERSSERVICE_ALLOWED_METHODS = Exclude<
  BASE_MICROSERVICE_ALLOWED_METHODS,
  'POST' | 'PATCH' | 'PUT' | 'DELETE'
>;

/**
 * This method scaffolds the query object for the "Orders Service" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {ORDERSSERVICE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
export function getOrdersServiceQuery<ArgT>(
  url: string,
  method: ORDERSSERVICE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.ORDERS_SERVICE)}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Search Engine" microservice API request.
 *
 * @param {ORDERSSERVICE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
export function getOrdersServiceGetParcelsQuery(
  method: ORDERSSERVICE_ALLOWED_METHODS,
  args: Array<string | number>,
  url: string,
): FetchArgs {
  const queryString = createGetParcelsBulkQueryString(args);

  return getOrdersServiceQuery(`${url}${queryString}`, method, args);
}

function createGetParcelsBulkQueryString(idsArray: Array<string | number>) {
  const queryString = arrayToQueryString(idsArray, 'ids');
  return `?${queryString}`;
}
