import { ReactNode, useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import classnames from 'classnames';
import omit from 'lodash/omit';

import {
  FormGroup,
  FormGroupProps,
  Label,
  LabelProps,
  FormTextProps,
  FormFeedback,
  FormFeedbackProps,
  InputGroupText,
} from 'reactstrap';

import { DatetimePicker, HelperText } from '@shippypro/design-system-web';
import { InputGroup, UncontrolledTooltip } from '@shippypro/design-system-web';
import {
  IInputProps,
  IInputGroupProps,
} from '@shippypro/design-system-web/types';

import { DateTimePickerProps } from 'react-flatpickr';

export interface ControlledDatetimeProps<FormType extends FieldValues>
  extends Pick<
    FormGroupProps,
    | 'row'
    | 'check'
    | 'inline'
    | 'floating'
    | 'disabled'
    | 'tag'
    | 'cssModule'
    | 'className'
  > {
  name: Path<FormType>;
  type?: IInputProps['type'];
  control: Control<FormType>;
  label?: ReactNode;
  placeholder?: string;
  inputProps?: Omit<DateTimePickerProps, 'value' | 'onChange'>;
  inputGroupProps?: IInputGroupProps;
  labelProps?: LabelProps;
  errorModes?: ('formFeedback' | 'tooltip')[];
  errorTextProps?: FormFeedbackProps;
  errorText?: ReactNode;
  successTextProps?: FormFeedbackProps;
  successText?: ReactNode;
  helperTextProps?: FormTextProps;
  helperText?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  icon?: ReactNode;
  endIcon?: ReactNode;
  endLabelIcon?: ReactNode;
  dataTest?: string;
  wrapperClassName?: string;
  inputClassName?: string;
}

export function ControlledDatetime<FormType extends FieldValues>({
  name,
  type,
  label,
  placeholder,
  control,
  inputProps,
  inputGroupProps,
  labelProps,
  helperTextProps,
  // here we can support one or more ways to show errors: FormFeedback, tooltip...
  errorModes = ['formFeedback'],
  errorTextProps,
  errorText,
  successTextProps,
  successText,
  helperText,
  className,
  inputClassName,
  wrapperClassName,
  disabled,
  required,
  icon,
  endIcon,
  endLabelIcon,
  dataTest,
  ...rest
}: ControlledDatetimeProps<FormType>) {
  const isRequired = required ?? inputProps?.required;
  const inputType = type ?? inputProps?.type;
  const tooltipTargetRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <div ref={tooltipTargetRef} className={wrapperClassName}>
      <Controller
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <FormGroup
            {...rest}
            className={classnames('!mb-0 w-full', className)}
          >
            <div className="flex space-x-1 label-icon-block">
              {label && (
                <Label {...labelProps}>
                  {label}
                  {isRequired && <span className="ml-[2px]">*</span>}
                </Label>
              )}
              {endLabelIcon}
            </div>
            {errorModes.includes('tooltip') && (error || errorText) && (
              <UncontrolledTooltip target={tooltipTargetRef} trigger="hover">
                {errorText ?? error?.message}
              </UncontrolledTooltip>
            )}
            <InputGroup
              {...inputGroupProps}
              className={classnames({
                'is-invalid': Boolean(error || errorText),
              })}
            >
              {icon && <InputGroupText>{icon}</InputGroupText>}
              <DatetimePicker
                className={classnames('!py-2 !px-4', inputClassName)}
                {...omit(inputProps)}
                onBlur={onBlur}
                onChange={onChange}
                onWheel={e => {
                  if (e.target) {
                    try {
                      (e.target as HTMLElement).blur();
                    } catch (e) {
                      //
                    }
                  }
                }}
                placeholder={placeholder ?? inputProps?.placeholder}
                value={value ?? ''}
                disabled={disabled ?? inputProps?.disabled}
                type={inputType}
                required={isRequired}
                data-test={dataTest ?? name}
              />
              {endIcon && <InputGroupText>{endIcon}</InputGroupText>}
            </InputGroup>
            {(error || errorText) && errorModes.includes('formFeedback') && (
              <FormFeedback {...errorTextProps}>
                {errorText ?? error?.message}
              </FormFeedback>
            )}
            {successText && (
              <FormFeedback valid {...successTextProps}>
                {successText}
              </FormFeedback>
            )}
            {helperText && (
              <HelperText {...helperTextProps}>{helperText}</HelperText>
            )}
          </FormGroup>
        )}
        name={name}
      />
    </div>
  );
}
