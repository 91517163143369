import { FC } from 'react';

import { IDefaultMultiAreaChartTooltipProps } from '@ds-web/components/molecules/ui/charts/multi-area-chart-tooltip/types';

import { Dot } from '@ds-web/components';

/**
 * Default tooltip for multi area charts
 * @param data
 * @return {JSX.Element}
 *
 */
const DefaultAreaChartTooltip: FC<IDefaultMultiAreaChartTooltipProps> = ({
  data,
}) => {
  return (
    <div className="recharts-custom-tooltip">
      <p className="font-light">{data.label}</p>
      <div className="active">
        {data?.payload?.map(i => {
          return (
            <div className="flex items-center" key={i.dataKey}>
              <Dot
                dotClassName="!relative !size-[10px] !mb-0 pt-[2px] mr-[6px]"
                color={i.payload.fill ?? i.color}
              />
              <span className="flex font-bold text-xl text-black">
                {i.payload[i.dataKey ?? 0]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultAreaChartTooltip;
