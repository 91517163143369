// ########################## [IMPORTANT LIB]
import classnames from 'classnames';
import { ReactNode, useContext } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Avatar,
  Dot,
} from '@shippypro/design-system-web';
import { Color, SizeWithXL, Status } from '@shippypro/design-system-web/enums';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [CONTEXTS]
import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';

// ########################## [TYPES]
import { MenuLink } from '@web/features/_global/types';
import styled from 'styled-components';

// ########################## [PAGE COMPONENTS]
import LastLevelMenuItem from './LastLevelMenuItem';

// ########################## [UTILS]
import {
  isNullOrEmptyString,
  getAvatarData,
} from '@shippypro/design-system-web/functions';

// ########################## [HOOKS]
import { useGetHealthCheck } from '@web/features/_global/hooks/api/useGetHealthCheck';
import useSideMenuUserLinks from '@web/features/_global/hooks/useSideMenuUserLinks';

interface INavbarAvatarMenuProps {
  showCollapsed: boolean;
  openedAccordionIds: string[];
  toggleAccordion: (id: string) => void;
  manageLink: (
    link: string | null,
    isExternal: boolean | null,
    isCustom: boolean | null,
  ) => void;
  isMenuSelected: (any) => number;
  name: string;
  email: string;
  img?: string;
  children?: ReactNode;
}

/**
 * This component manages the user menu in the sidebar menu
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const UserMenu: React.FC<INavbarAvatarMenuProps> = ({
  showCollapsed,
  openedAccordionIds,
  toggleAccordion,
  manageLink,
  isMenuSelected,
  name,
  img,
  email,
}) => {
  const { t } = useTranslation(),
    userHelpTrans = translations.nav.help,
    userMenuTrans = translations.nav.user;

  const { openHelpSection } = useContext(HelpSectionContext)!;

  const truncatedEmail =
    email.length > 20 ? `${email.substring(0, 17)}...` : '';

  const { content, color } = getAvatarData(name || 'Loading ...', true);

  const { isPortalHealthy } = useGetHealthCheck();

  const list: MenuLink = useSideMenuUserLinks();

  return (
    <div className="user-menu menu-category mt-auto">
      <Accordion
        open={openedAccordionIds}
        toggle={toggleAccordion}
        className="accordion-without-arrow bg-primary"
      >
        <AccordionItem className="accordion-without-arrow">
          <AccordionHeader
            targetId={'user-menu'}
            className="flex flex-col items-center justify-center min-h-[52px] md:min-h-[38px] accordion-without-arrow"
          >
            <div
              className={classnames('relative', {
                'w-[80%]': showCollapsed,
                'w-full': !showCollapsed,
              })}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                id="sidemenu-help-button"
                className={classnames(
                  'w-full px-3.5 py-[0.786rem] flex flex-nowrap justify-center items-center !gap-1 !rounded-md !bg-[color:--shp-color-bg-dark-highlight] text-white text-sm',
                )}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  openHelpSection();
                }}
              >
                <IconHelper
                  icon="IconHelp"
                  size={20}
                  className={classnames({
                    'mx-auto': showCollapsed,
                  })}
                />
                {!showCollapsed && (
                  <span className="text-nowrap leading-normal">
                    {t(userHelpTrans.help)}
                  </span>
                )}
              </a>
              <Dot
                color={Color.accent}
                isPulsing
                // Refer to the scathing comment in the `useManageLiveChat` hook to understand why this is here
                // and it's not managed with a reactive state
                id="unreadchat-usermenu"
                dotClassName="absolute !-top-1 -right-2.5 hidden"
              />
            </div>
            <div className="menu-link flex !px-0 items-center justify-center relative w-full">
              <StyledAvatar
                content={content}
                color={color}
                status={
                  isPortalHealthy === undefined
                    ? undefined
                    : isPortalHealthy
                      ? Status.online
                      : Status.busy
                }
                size={SizeWithXL.md}
                className={`${!img ? 'o5' : ''} cinitial`}
                img={isNullOrEmptyString(img) ? undefined : img}
              />

              {showCollapsed || (
                <div className="d-flex flex-column px-1">
                  <div
                    className="font-normal text-[16px] md:text-[14px] text-white tracking-normal"
                    data-test={`userEmail`}
                  >
                    {name}
                  </div>
                  <div className="text-[14px] mb-0 max-width-full tracking-normal text-[color:--shp-color-text-section-title-inverse]">
                    {truncatedEmail || email}
                  </div>
                </div>
              )}
            </div>
          </AccordionHeader>
          {/*Second level menu section*/}
          <AccordionBody
            accordionId={'user-menu'}
            className={classnames({
              hidden: showCollapsed,
            })}
          >
            {list.data.map(
              (menuItem, j) =>
                menuItem.isVisible && (
                  <LastLevelMenuItem
                    key={j}
                    menuItem={menuItem}
                    i={j}
                    manageLink={manageLink}
                    isMenuSelected={isMenuSelected}
                    menuTrans={userMenuTrans}
                  ></LastLevelMenuItem>
                ),
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

const StyledAvatar = styled(Avatar)`
  .avatar-content {
    width: 40px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }

  img {
    width: 40px !important;
    height: 40px !important;
  }

  [class*='avatar-status-'] {
    width: 13px !important;
    height: 13px !important;
  }
`;

export default UserMenu;
