// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

// ########################## [PAGE COMPONENTS]
import StyledContainer from './styles';
import { SideTabsNavigator } from './components/body/SideTabsNavigator';
import TitleHeading from '@ds-web/components/templates/with-tabs/components/heading';

export interface ISectionWithSideTabsProps extends IPageWithTabs {
  fullHeight?: boolean;
  unpadCards?: boolean;
}

/**
 * [LAYOUT CARD WITH SIDE TABS] This components holds the main layout arrangement for a page
 * with a body on the right and navigation tabs at the left.
 * It wraps a `SideTabsNavigator` component, which hold the side tabs and the actual body.
 *
 * @interface ISectionWithSideTabs
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const SectionWithSideTabs: FC<ISectionWithSideTabsProps> = memo(
  ({
    headingTitle,
    tabs,
    activeTabIndex,
    toggle,
    rightmostCTA,
    unpadCards = true,
    fullHeight = true,
    loadInvisibleTabs = false,
  }) => {
    const selectedTab = tabs[activeTabIndex] ?? tabs[0];

    return (
      <StyledContainer
        className="flex flex-col flex-nowrap"
        $fullHeight={fullHeight}
        $unpadCards={unpadCards}
      >
        {(headingTitle || selectedTab.secondaryContent.heading) && (
          <TitleHeading
            headingTitle={headingTitle}
            heading={selectedTab.secondaryContent.heading}
          />
        )}

        <SideTabsNavigator
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          toggle={toggle}
          loadInvisibleTabs={loadInvisibleTabs}
          rightmostCTA={rightmostCTA}
        />
      </StyledContainer>
    );
  },
);

SectionWithSideTabs.displayName = 'SectionWithSideTabs_memoized';
