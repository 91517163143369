import { Fragment, memo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';

import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

import { SentryRoutes } from '@web/utils/@sentry';

import {
  AuthCodeHandler,
  authCodeRequestPath,
  authCodeExchangePath,
} from '@web/features/_global/oauth';
import { Logout } from '@web/features/_global/authenticated';

import useGetLazyLoad from '@web/hooks/useGetLazyLoad';
import { useHasActivePlan } from './_global/hooks/api/useHasActivePlan';
import useLDFlag from '@web/hooks/useLDFlags';

// Redirection imports
import RedirectionCheckComponent from './_redirection';

export const AppRoutes: React.FC = memo(() => {
  const { isMobile } = useGetDeviceSize();

  // sub-routing: it needs to be on this level to trick react-router and the browser
  // to not delete the pathname hash while lazy loading the pages
  const { pathname, hash } = useLocation();

  const { uiAppReactiveSearchBar, uiAppNewAnalytics, settingsOnUiApp } =
    useLDFlag();

  /**
   * LAZY-LOADED ROUTES.
   *
   * Routes loaded with the lazyLoad function with a custom hook
   */
  // --- [Common Routes]
  const NotFoundPage = useGetLazyLoad('404', 'NotFoundPage');
  const HomePage = useGetLazyLoad('home');

  // --- [Onboarding Routes]
  const Onboarding = useGetLazyLoad('onboarding');

  // --- [DELIVER Routes]
  const Ship = useGetLazyLoad('ship');
  const Order = useGetLazyLoad('order');

  // --- [MY DOCUMENTS Routes]
  const MyDocuments = useGetLazyLoad('fulfillment/documents', 'Documents');

  // --- [ANALYTICS Routes]
  const Analysis = useGetLazyLoad('analysis');
  const AnalyticsDashboards = useGetLazyLoad(
    'analytics-dashboards',
    'AnalyticsDashboards',
  );
  const NewAnalytics = useGetLazyLoad('data/analytics', 'Analytics');
  const NewAnalyticsSettings = useGetLazyLoad(
    'data/analytics-settings',
    'AnalyticsSettings',
  );
  const InvoiceAnalysisOverview = useGetLazyLoad(
    'invoice-analysis/pages/invoice-analysis-overview',
    'InvoiceAnalysisOverview',
  );
  const InvoiceAnalysisDetail = useGetLazyLoad(
    'invoice-analysis/pages/invoice-analysis-detail',
    'InvoiceAnalysisDetail',
  );

  // --- [Settings Routes]
  const Settings = useGetLazyLoad('foundation/settings', 'Settings');

  // --- [Data Vault Routes]
  //TODO: Leverage lazy loading
  const DataVault = useGetLazyLoad('data-vault', 'DataVault');

  const unpaddedRoutes = [
    /\/onboarding/,
    /^\/ship$/,
    /^\/my-documents$/,
    /^\/analytics\/carrierinvoiceanalysis(\/\d*)?$/,
    /^\/settings$/,
  ];
  const mustNotPadRoute = unpaddedRoutes.some(rx => rx.test(pathname));

  return (
    <ContentWrapperStyle
      className={classnames('pt-0 flex !flex-col items-center h-full', {
        'content content-wrapper': !mustNotPadRoute,
        '!px-4': isMobile && !mustNotPadRoute,
        '!px-9': !isMobile && !mustNotPadRoute,
        '!h-[calc(100%-4rem)]': uiAppReactiveSearchBar,
      })}
    >
      <SentryRoutes>
        {/* REDIRECTION CHECKS */}
        <Route path="redirect-check" element={<RedirectionCheckComponent />} />

        {/* HOME ROUTES */}
        <Route path="/home/*" element={<HomePage />} />

        {/* ONBOARDING ROUTES */}
        <Route path="/onboarding/*" element={<Onboarding />} />

        {/* "DELIVER" ROUTES */}
        <Route path="/ship" element={<Ship hash={hash} />} />
        <Route path="/orders">
          <Route
            path="toship/:id"
            element={<PaidRoute element={<Order category="toship" />} />}
          />
          <Route
            path="toship"
            element={<PaidRoute element={<Order category="toship" />} />}
          />
          <Route path="shipped/:id" element={<Order category="shipped" />} />
          <Route path="shipped" element={<Order category="shipped" />} />
          <Route index element={<NotFoundPage />} />
        </Route>

        {/* "MY DOCUMENTS" ROUTES */}
        <Route path="/my-documents" element={<MyDocuments />} />

        {/* "ANALYTICS" ROUTES */}
        <Route path="/data">
          <Route
            path="analysis/*"
            element={<PaidRoute element={<Analysis />} />}
          />
          <Route
            path="dashboards/*"
            element={<PaidRoute element={<AnalyticsDashboards />} />}
          />
          {uiAppNewAnalytics && (
            <Route
              path="optimizer/*"
              element={<PaidRoute element={<NewAnalytics />} />}
            />
          )}

          {uiAppNewAnalytics && (
            <Route
              path="optimizer/settings"
              element={<PaidRoute element={<NewAnalyticsSettings />} />}
            />
          )}
          <Route
            path="carrierinvoiceanalysis"
            element={<PaidRoute element={<InvoiceAnalysisOverview />} />}
          />
          <Route
            path="carrierinvoiceanalysis/:id"
            element={<PaidRoute element={<InvoiceAnalysisDetail />} />}
          />
        </Route>

        {/* "SETTINGS" ROUTES */}
        {settingsOnUiApp && <Route path="/settings" element={<Settings />} />}

        {/* Data Vault routes */}
        <Route
          path="/data-vault"
          element={<PaidRoute element={<DataVault />} />}
        />

        <Route path="*" element={<NotFoundPage />} />
      </SentryRoutes>
    </ContentWrapperStyle>
  );
});

const ContentWrapperStyle = styled.div`
  flex-flow: wrap;
`;

const OfflineUrls = {
  authCodeRequestPath,
  authCodeExchangePath,
  logout: '/logout',
};

export const IsOfflineUrl = (off: string) => {
  return Object.values(OfflineUrls).indexOf(off) !== -1;
};

export const OfflineRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        path={OfflineUrls.authCodeRequestPath}
        element={<AuthCodeHandler />}
      />
      <Route
        path={OfflineUrls.authCodeExchangePath}
        element={<AuthCodeHandler />}
      />
      <Route path={OfflineUrls.logout} element={<Logout />} />
    </SentryRoutes>
  );
};

const PaidRoute = ({ element }) => {
  const {
    data: { hasActivePlan, redirectPath },
  } = useHasActivePlan();

  /* istanbul ignore if */
  if (!hasActivePlan) {
    window.location.replace(`${process.env.NX_LEGACY_URL}/${redirectPath}`);

    return Fragment;
  }

  return element;
};
