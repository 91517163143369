import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

import { showErrorToastr } from '@shippypro/design-system-web/functions';

import { portalApiGWBaseURL } from '@web/utils/@axios';

import { i18n, translations } from '@shippypro/translations';

import {
  GetJWTToken,
  getOrCreateRefreshTokenPromise,
} from '@web/utils/functions';

export const getBaseQueryWithReauth: (
  baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
) => BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = baseQuery => {
  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      await getOrCreateRefreshTokenPromise();
      result = await baseQuery(args, api, extraOptions);
    } else if (result.error && result.error.status === 403) {
      //Determine if the 403 was triggered by a Company domain
      const requestDomainParts = result.meta?.request.url
        .split('/')[2]
        .split('.');
      if (requestDomainParts === undefined) {
        // We cannot determine the URL from which the request was issued
        return result;
      }
      const requestDomain =
        requestDomainParts[requestDomainParts.length - 2] +
        '.' +
        requestDomainParts[requestDomainParts.length - 1];
      const companyDomainParts =
        process.env.NX_API_GATEWAY?.split('/')[2].split('.');
      if (companyDomainParts === undefined) {
        //Cannot reliably determine the top domain of the company
        return result;
      }
      const companyDomain =
        companyDomainParts[companyDomainParts.length - 2] +
        '.' +
        companyDomainParts[companyDomainParts.length - 1];
      if (requestDomain === companyDomain) {
        //Delete the old tokens
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('portal-token');
        //Redirect to the PKCE entry-point
        window.location.reload();
        return result;
      }
      //If so, and if we are within the maximum numbers of retries, destroy the
      //token and re-initiate the PKCE issuing
      await getOrCreateRefreshTokenPromise();
      result = await baseQuery(args, api, extraOptions);
    }
    return result;
  };

  return baseQueryWithReauth;
};

export const prepareHeaders:
  | ((
      headers: Headers,
      api: Pick<
        BaseQueryApi,
        'type' | 'getState' | 'extra' | 'endpoint' | 'forced'
      >,
    ) => MaybePromise<Headers>)
  | undefined = headers => {
  const token = GetJWTToken();
  headers.set('authorization', `Bearer ${token}`);
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  return headers;
};

export const getGatewayBaseQuery = () => {
  return getBaseQueryWithReauth(
    fetchBaseQuery({
      baseUrl: portalApiGWBaseURL,
      prepareHeaders,
    }),
  );
};

export function getGatewayQuery<ActionT, ArgT>(
  action: ActionT,
  args?: ArgT,
): FetchArgs {
  return {
    url: '',
    method: 'POST',
    body: {
      action,
      payload: args ?? {},
    },
  };
}

/**
 * This function takes the response of an API Call and checks if there was an error
 * at the Gateway system level (XSS Attack, SegFault, Serialisation Error, System unreachable...).
 * If this is the case, the function then notifies the user and returns NULL instead of the response.
 *
 * @param response The response taken from the RTK Query API hook
 * @param paramStringMap Optional map to perform a mapping from parameter name and a friendly name to display
 * @returns {T} The expected data, or NULL if a system error was found
 */
export async function handleAPIError<T>(
  response:
    | {
        data: T;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      },
  paramStringMap: Map<string, string> = new Map(),
): Promise<T | null> {
  if (!response['data'] && response['error']) {
    let msg = parseAPIError(response['error']);

    await notifyAPIError(msg, paramStringMap);

    return null;
  }

  return response['data'] as T;
}

/**
 * This function takes an RTK Query serialised error and turns it
 * into a readable message string to be handled by other logics.
 *
 * @param error The RTK Query BE system error
 * @returns {string} the actual error message string
 */
export function parseAPIError(
  error: FetchBaseQueryError | SerializedError | undefined,
): string {
  const safeError = error ?? {};
  const data = safeError['data'];

  /* istanbul ignore if */
  if (typeof data === 'string') return data;
  else if (typeof data === 'undefined' && !safeError['message'])
    return safeError['error'] ?? '';
  else if (typeof data === 'undefined') return safeError['message'] ?? '';
  else if (data['message']) return data['message'];
  else if (data['error']) return data['error'];

  /* istanbul ignore next */
  return '';
}

/**
 * This function takes an error message string and notifies the user about it.
 * It also checks if said error is of a specific type to see if a special toastr
 * must be used for it.
 *
 * @param error The error string parsed from the API response
 * @param paramStringMap Optional map to perform a mapping from parameter name and a friendly name to display
 */
export async function notifyAPIError(
  error: string,
  paramStringMap: Map<string, string> = new Map(),
): Promise<void> {
  const t = await i18n.then(t => t),
    transErr = translations.common.errors.error,
    transXss = translations.common.errors.xssError;

  // Anti-XSS Check
  /* istanbul ignore if */
  if (`${error}`.includes('CouldNotValidatePayloadInput')) {
    const actionPattern = /action:\s*([^\s|]+)/;
    const propertyPattern = /property:\s*([^\s|]+)/;

    const actionMatch = error.match(actionPattern);
    const paramMatch = error.match(propertyPattern);

    const action = actionMatch ? actionMatch[1] : 'NONE';
    const unformattedParam = paramMatch ? paramMatch[1] : 'NONE';
    const param = paramStringMap.has(unformattedParam)
      ? t(paramStringMap.get(unformattedParam)!)
      : unformattedParam;

    error = t(transXss, { param, action });
  }
  if (error === undefined || error === '') {
    error = t(transErr);
  }
  showErrorToastr(t(transErr), error);
}

export interface CustomRTKQueryOptions {
  skip?: boolean;
  pollingInterval?: number;
}

export const tagTypes = [
  'BulkAction',
  'Metrics',
  'Onboarding',
  'Setup',
  'Changelog',
  'User',
  'Plan',
  'Marketplaces',
  'Carriers',
  'Ship',
  'Order',
  'Rate',
  'AddressBook',
  'OrdersCount',
  'OrdersCountCarrier',
  'ETDDocuments',
  'ETDDocumentsBulkMS',
  'OrderParcels',
  'OrderParcelsBulk',
  'OrderParcelsBulkMS',
  'OrderGetRatesBulk',
  'OrderGetCarrierOptionsBulk',
  'Parcel',
  'AnalyticsDashboard',
  'TableCarriers',
  'UserTabs',
  'DescriptionsBook',
  'AutoMergeCapability',
  'GetExcelColumns',
  'GetExcelColumnsTemplates',
  'Manifests',
  'PickingLists',
  'CustomsDocuments',
  'AnalyticsUserViews',
  'AnalyticsKpiData',
  'AnalyticsCarriers',
  'SenderCountries',
  'RecipientCountries',
  'AnalyticsGraphData',
  'SenderCities',
  'RecipientCities',
  'AnalyticsPerformancesByCountry',
  'AnalyticsPerformancesByCarrier',
  'AnalyticsMap',
  'GlobalSearch',
  'AnalyticsPerformancesBySenderCity',
  'AnalyticsPerformancesByRecipientCity',
  'AnalyticsPerformancesByCarrierService',
  'AnalyticsCarrierServices',
  'AnalyticsExceptionPerformancesByCountry',
  'AnalyticsExceptionPerformancesByCarrier',
  'AnalyticsExceptionPerformancesByCarrierService',
  'AnalyticsExceptionsBySecondTier',
  'AnalyticsExceptionsByThirdTier',
  'ExpectedTransitTime',
  'CarrierTransitTime',
  'AnalyticsMultiKpisGraphData',
  'GetAutoRetryStatus',
];

export const rootApi = createApi({
  reducerPath: 'api',
  baseQuery: getGatewayBaseQuery(),
  endpoints: () => ({}),
  tagTypes,
  extractRehydrationInfo(action, { reducerPath }) {
    /* istanbul ignore if */
    if (action.payload && action.payload[reducerPath] !== undefined) {
      // we don't want to rehydrate these
      action.payload[reducerPath].config = {};
      action.payload[reducerPath].subscriptions = {};
      action.payload[reducerPath].provided = {};
      return action.payload[reducerPath];
    }
  },
});
