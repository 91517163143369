import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';

import { getMicroserviceUrl } from '@web/utils/@microservices/functions';

import { MicroserviceNames } from '@web/utils/@microservices/types';

import { objectToQueryString } from '@web/utils/functions/objects';
import { WorkFlowEngineAPIEndpoints } from '@web/features/ship/types';

type WORKFLOWENGINE_ALLOWED_METHODS = Exclude<
  BASE_MICROSERVICE_ALLOWED_METHODS,
  'PATCH' | 'PUT' | 'DELETE'
>;

/**
 * This method scaffolds the query object for the "Workflow Engine" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {WORKFLOWENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
function getWorkflowEngineQuery<ArgT>(
  url: string,
  method: WORKFLOWENGINE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.WORKFLOW_ENGINE)}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Workflow Engine" microservice API request.
 * It specifically get the auto-retry status of a workflow
 *
 * @param {WORKFLOWENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {args} Object containing the userId and workflowName.
 * @returns {FetchArgs} The query object.
 */
export function getAutoRetryStatusQuery(
  method: WORKFLOWENGINE_ALLOWED_METHODS,
  args: { userId: number; workflowName: string },
): FetchArgs {
  const queryString = createGetWorkflowStatusQueryString(args);

  return getWorkflowEngineQuery(
    `${WorkFlowEngineAPIEndpoints.WorkflowGetStatus}${queryString}`,
    method,
    args,
  );
}

/**
 * Creates a query string for getting the workflow status.
 *
 * @param {object} args The arguments to include in the query string.
 * @returns {string} The query string.
 */
function createGetWorkflowStatusQueryString(args: {
  userId: number;
  workflowName: string;
}): string {
  return `?${objectToQueryString(args)}`;
}

/**
 * This method scaffolds the query object for the "Workflow Engine" microservice API request.
 * It specifically targets the "workflow" endpoint part of the engine.
 * It changes the auto-retry status of the workflow.
 *
 * @template ArgT - The type of the arguments object, which must include `userId`, `workflowName`, and `status`.
 * @param {WORKFLOWENGINE_ALLOWED_METHODS} method - The method to be used for the query.
 * @param {ArgT} args - The arguments object containing `userId`, `workflowName`, and `status`.
 * @returns {FetchArgs} The generated query for the workflow engine.
 */
export function getChangeAutoRetryStatusQuery<ArgT>(
  method: WORKFLOWENGINE_ALLOWED_METHODS,
  args: ArgT,
): FetchArgs {
  return getWorkflowEngineQuery(
    WorkFlowEngineAPIEndpoints.WorkflowsMain,
    method,
    args,
  );
}
