import { FC, memo } from 'react';
import isNumber from 'lodash/isNumber';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { IAnalyticsCardProps } from './types';

import {
  CardMetrics,
  ChartMetricsSkeleton,
  MetricTrend,
  CardLoading,
} from '@ds-web/components';
/**
 * A component that wraps a card with title header a centered content with analytics data
 * and children (a table, a graph, etc.)
 *
 * @interface IAnalyticsCardProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const AnalyticsCard: FC<IAnalyticsCardProps> = memo(
  ({
    summaryData,
    name,
    isLoading = false,
    titleTooltip,
    isFetching = false,
    isError = false,
    dataTest,
    className,
    children,
    rightSideOptWidget,
    trendPopover,
  }) => {
    const { i18n, t } = useTranslation();
    const transCommon = translations.common;

    const cleanedName = name.replace(/[^a-zA-Z0-9]+/g, '-');
    const kpiCardDataTest = cleanedName + '-card';
    const code = cleanedName + '-code';

    if (isLoading)
      return <ChartMetricsSkeleton className="max-h-[30rem] mb-2" />;

    return (
      <CardMetrics
        id="analytics-card"
        className={classnames(className, '!h-auto', {
          'is-fetching': isFetching || isLoading,
        })}
        code={code}
        title={name}
        dynamicBodyHeight
        titleTooltip={titleTooltip}
        data-test={dataTest ?? code}
        rightSideOptWidget={rightSideOptWidget}
      >
        {isFetching ? (
          <CardLoading />
        ) : isError ? (
          <span className="text-light-purpleish">
            {t(transCommon.errors['genericAnalytics'])}
          </span>
        ) : (
          <section
            id="chart-container"
            className="w-full flex flex-col justify-between items-start"
          >
            {summaryData && (
              <div
                id="chart-summary-block"
                className="w-full flex flex-row justify-start items-baseline !gap-6"
              >
                {summaryData?.map(
                  ({
                    value,
                    unit,
                    trend,
                    subtitle,
                    invertTrendColor,
                    previousValue,
                    popoverId,
                    reverseUnitValueOrder,
                  }) => {
                    const valueParsed =
                      value.toLocaleString(i18n.language) || 0;
                    return (
                      <div className="flex items-center" key={popoverId}>
                        <span id="chart-summary" className="flex flex-col">
                          <div className="flex flex-row">
                            <span
                              className={classnames(
                                'font-bold text-3xl text-black flex flex-row gap-[6px]',
                                { '!flex-row-reverse': reverseUnitValueOrder },
                              )}
                            >
                              {unit && <span>{unit}</span>}
                              <span>{valueParsed || 0}</span>
                            </span>
                            {isNumber(trend) && (
                              <MetricTrend
                                id={kpiCardDataTest}
                                value={trend}
                                previousValue={previousValue}
                                invertTrendColor={invertTrendColor}
                                iconUp="IconArrowUpRight"
                                iconDown="IconArrowDownLeft"
                                className="pt-[0.5rem] ml-2 font-normal"
                                trendPopover={trendPopover}
                                popoverId={popoverId}
                              />
                            )}
                          </div>
                          {subtitle && (
                            <p className="subtitle text-gray-500 text-sm font-light truncate">
                              {subtitle}
                            </p>
                          )}
                        </span>
                      </div>
                    );
                  },
                )}
              </div>
            )}
            <div
              id="analytics-card-container"
              className={classnames(
                {
                  '!mt-5': summaryData,
                },
                'recharts-wrapper',
              )}
            >
              {children}
            </div>
          </section>
        )}
      </CardMetrics>
    );
  },
);
