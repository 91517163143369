import { FunctionComponent, ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import {
  DropdownIndicatorProps,
  GroupBase,
  IndicatorSeparatorProps,
  MultiValue,
  SingleValueProps,
  PlaceholderProps,
} from 'react-select';
import AsyncCreatableSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable';

import {
  UncontrolledTooltip,
  IconOption,
  SingleSelectedIconOption,
  AvatarOption,
  SingleSelectedAvatarOption,
  ImageOption,
  MultiSelectedImageOption,
  HelperText,
  WarningText,
} from '@shippypro/design-system-web';

import {
  FormGroup,
  FormGroupProps,
  Label,
  LabelProps,
  FormTextProps,
  FormFeedback,
  FormFeedbackProps,
} from 'reactstrap';

export interface ControlledAsyncCreatableSelectProps<
  FormType extends FieldValues,
  OptionType,
  IsMulti extends boolean = boolean,
> extends Pick<
    FormGroupProps,
    'row' | 'check' | 'inline' | 'floating' | 'disabled' | 'tag' | 'cssModule'
  > {
  name: Path<FormType>;
  options: OptionType[];
  control: Control<FormType>;
  label?: ReactNode;
  selectProps?: Omit<
    AsyncCreatableProps<OptionType, IsMulti, GroupBase<OptionType>>,
    'value' | 'onChange'
  >;
  onCreateOptionCallback?: Function;
  labelProps?: LabelProps;
  errorModes?: ('formFeedback' | 'tooltip')[];
  errorTextProps?: FormFeedbackProps;
  errorText?: ReactNode;
  warningTextProps?: FormFeedbackProps;
  warningText?: string[];
  successTextProps?: FormFeedbackProps;
  successText?: ReactNode;
  helperTextProps?: FormTextProps;
  helperText?: ReactNode;
  hasIcons?: boolean;
  hasAvatars?: boolean;
  hasImages?: boolean;
  dropdownIndicator?: FunctionComponent<
    DropdownIndicatorProps<OptionType, boolean, GroupBase<OptionType>>
  >;
  indicatorSeparator?: FunctionComponent<
    IndicatorSeparatorProps<OptionType, boolean, GroupBase<OptionType>>
  >;
  separator?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  endLabelIcon?: ReactNode;
  className?: string;
  /**
   * Please use this boolean prop when you have an array of objects as options
   * (i.e. [{ name: 'EUR', label: 'Euro' }]) but you want the selected value to be a string
   * (i.e. 'EUR'). If useExtractedValue is true, selectProps?.getOptionValue will be used to
   * "extract" the value from the object. (No, we can't just use selectProps?.getOptionValue
   * because we need it also on selects that must return the full option object, we need it
   * to distinguish different objects)
   */
  useExtractedValue?: boolean;
  // to override the component of the selected option
  overrideSingleValue?: ({
    children,
    ...props
  }: SingleValueProps<
    OptionType,
    IsMulti,
    GroupBase<OptionType>
  >) => JSX.Element;
  overridePlaceholder?: ({
    children,
    ...props
  }: PlaceholderProps<
    OptionType,
    IsMulti,
    GroupBase<OptionType>
  >) => JSX.Element;
}

export function ControlledAsyncCreatableSelect<
  FormType extends FieldValues,
  OptionType,
>({
  name,
  label,
  options,
  control,
  selectProps,
  labelProps,
  helperTextProps,
  // here we can support one or more ways to show errors: FormFeedback, tooltip...
  errorModes = ['formFeedback'],
  errorTextProps,
  errorText,
  warningTextProps,
  warningText,
  successTextProps,
  successText,
  helperText,
  className,
  hasIcons,
  hasAvatars,
  hasImages,
  dropdownIndicator,
  indicatorSeparator,
  separator = true,
  isClearable = false,
  useExtractedValue,
  disabled,
  required,
  endLabelIcon,
  overrideSingleValue,
  overridePlaceholder,
  onCreateOptionCallback,
  ...rest
}: ControlledAsyncCreatableSelectProps<FormType, OptionType>) {
  const tooltipTargetId = `${name.replace(/\./g, '-')}-tooltip-target`;

  // TODO: SHIP-1148
  // while refactoring controlled selects, look for a more elegant way for managing built in "components" object
  const iconComponent = hasIcons
    ? {
        Option: IconOption,
        Control: SingleSelectedIconOption,
      }
    : hasAvatars
      ? {
          Option: AvatarOption,
          Control: SingleSelectedAvatarOption,
        }
      : hasImages
        ? {
            Option: ImageOption,
            MultiValue: MultiSelectedImageOption,
          }
        : {};

  const dropdownIndicatorComponent = dropdownIndicator
    ? { DropdownIndicator: dropdownIndicator }
    : {};

  const actualComponents = {
    ...iconComponent,
    ...dropdownIndicatorComponent,
  };

  if (overrideSingleValue)
    actualComponents['SingleValue'] = overrideSingleValue;

  if (overridePlaceholder)
    actualComponents['Placeholder'] = overridePlaceholder;

  return (
    <div id={tooltipTargetId}>
      <Controller
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <FormGroup
            {...rest}
            className={classnames('!mb-0 w-full', className)}
          >
            <div className="flex space-x-1 label-icon-block">
              {label && (
                <Label {...labelProps}>
                  {label}
                  {required && <span className="ml-[2px]">*</span>}
                </Label>
              )}
              {endLabelIcon}
            </div>
            {errorModes.includes('tooltip') && (error || errorText) && (
              <UncontrolledTooltip target={tooltipTargetId} trigger="hover">
                {errorText ?? error?.message}
              </UncontrolledTooltip>
            )}
            <AsyncCreatableSelect
              components={actualComponents}
              options={options}
              onChange={newValue => {
                if (selectProps?.getOptionValue && useExtractedValue) {
                  onChange(
                    selectProps?.isMulti
                      ? (newValue as MultiValue<OptionType>)?.map(v =>
                          selectProps.getOptionValue!(v),
                        )
                      : selectProps?.getOptionValue(newValue as OptionType),
                  );
                } else {
                  onChange(newValue);
                }
              }}
              onCreateOption={
                selectProps?.onCreateOption
                  ? selectProps.onCreateOption
                  : onCreateOptionCallback
                    ? val => {
                        onCreateOptionCallback();
                        onChange(val);
                      }
                    : undefined
              }
              onBlur={onBlur}
              classNamePrefix="select"
              className={classnames(
                `form-select-element ${selectProps?.className}`,
                {
                  'is-valid': Boolean(successText),
                  'is-invalid': Boolean(error || errorText),
                  'is-warning': warningText,
                },
              )}
              isDisabled={disabled || selectProps?.isDisabled}
              value={
                selectProps?.getOptionValue && useExtractedValue
                  ? selectProps?.isMulti
                    ? ((value as MultiValue<OptionType>)?.map(v =>
                        options.find(option =>
                          isEqual(selectProps.getOptionValue!(option), v),
                        ),
                      ) as MultiValue<OptionType>)
                    : value
                      ? options.find(
                          option =>
                            selectProps.getOptionValue!(option) === value,
                        ) || {
                          ...value,
                          value: value.replaceAll('%', ''),
                          label: value.replaceAll('%', ''),
                        }
                      : undefined
                  : value || undefined
              }
              allowCreateWhileLoading={
                selectProps?.allowCreateWhileLoading || false
              }
              createOptionPosition={
                selectProps?.createOptionPosition || 'first'
              }
              {...selectProps}
            />
            {/* hidden input used for accessing value from Cypress */}
            <input
              type="hidden"
              data-test="select__value"
              value={
                value !== undefined &&
                value !== null &&
                !['number', 'string'].includes(typeof value) &&
                selectProps?.getOptionValue
                  ? selectProps?.getOptionValue(value)
                  : value
              }
            />
            {(error || errorText) && errorModes.includes('formFeedback') && (
              <FormFeedback {...errorTextProps}>
                {errorText ?? error?.message}
              </FormFeedback>
            )}
            {successText && (
              <FormFeedback valid {...successTextProps}>
                {successText}
              </FormFeedback>
            )}
            {helperText && (
              <HelperText {...helperTextProps}>{helperText}</HelperText>
            )}
            {warningText &&
              warningText.map(text => (
                <WarningText {...warningTextProps}>{text}</WarningText>
              ))}
          </FormGroup>
        )}
        name={name}
      />
    </div>
  );
}
