import { Address } from '@web/types/common/address';
import { AddressType } from '@web/types/enums';
import { Country } from '@web/types/global';
import { ValidationRules } from './types/validation';

/* --- STATE --- */
export interface ManageOrderModalState {}

export enum ManageOrderModalAPIActions {
  OrderShipOrEdit = 'OrderShip',
  OrderGetParcels = 'OrderGetParcels',
  OrderGetRates = 'OrderGetRates',
  OrderGetRatesBulk = 'OrderGetRatesBulk',
  OrderGetCustomInfo = 'OrderGetCustomInfo',
  OrderGetCarrierOptions = 'OrderGetCarrierOptions',
  OrderGetCarrierOptionsBulk = 'OrderGetCarrierOptionsBulk',
  OrderGetETDDocuments = 'OrderGetETDDocuments',
  OrderUploadETDDocuments = 'OrderUploadETDDocuments',
  OrderDeleteETDDocuments = 'OrderDeleteETDDocuments',
  OrderEditETDDocumentType = 'OrderEditETDDocumentType',
  OrderEditRecipientAddress = 'OrderEditRecipientAddress',
  OrderDeleteParcel = 'OrderDeleteParcel',
  OrderAddParcel = 'OrderAddParcel',
  OrderEditParcel = 'OrderEditParcel',
  OrderEditItemsDescription = 'OrderEditItemsDescription',
  UserGetDescriptionsBook = 'UserGetDescriptionsBook',
  OrderGetFullGLSItalyError = 'OrderGetFullGLSItalyError',
  OrderCheckAddress = 'OrderCheckAddress',
  // [EXPORT NEW ACTIONS ABOVE] < Needed for generating API Hooks seamlessly
}

export enum ManageOrderModalMSAPIEndpoints {
  OrderToShipGetParcels = 'orders-to-ship/parcels',
  OrderToShipGetCustomInfo = 'customsDocument/customsInfo',
  OrderToShipGetETDDocuments = 'customsDocument/etdDocuments',
  OrderCheckUniqueExternalId = 'orders/check-unique-transaction-id',
}

export type FeatureState = ManageOrderModalState;

/* ADDRESS */

export type EditAddressPayload = {
  id: number;
  editAddress: Address;
};

export type CustomerAddressQueryPayload = {
  name: string;
  company: string | null;
  email: string;
  phone: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string | Country;
};

export type CustomerCreateAddressMutationPayload = {
  address: CustomerAddressQueryPayload;
  address_type: AddressType;
};

export type CustomerEditAddressMutationPayload = {
  address_id: number;
  address: CustomerAddressQueryPayload;
  address_type: AddressType;
};

export type ValidationRulesResult = {
  carrierName: string;
  rules: ValidationRules;
};

export type ValidationRulesPayload = {
  carrier: string;
  version: string;
};

export type OrderGetFullGLSItalyErrorQueryPayload = {
  order_id: number;
  to_address_street1: string;
  to_address_state: string;
  to_address_zip: string;
  to_address_city: string;
};

export type OrderGetFullGLSItalyErrorQueryResult = {
  result: boolean;
  suggestions: GLSItalyAddress[];
  message: string | null;
};

export type GLSItalyAddress = {
  SiglaRegione: string;
  DescrizioneRegione: string;
  SiglaProvincia: string;
  DescrizioneProvincia: string;
  Cap: {}; // YET TO FIND TYPE FROM WSERVICE
  Frazione: {}; // YET TO FIND TYPE FROM WSERVICE
  Comune: string;
  Indirizzo: string;
  InoltroInProvincia: {}; // YET TO FIND TYPE FROM WSERVICE
  KmDistanzaSede: string;
  Sede: string;
  Zona: string;
  TempoDiResa: string;
  LocalitaDisagiata: string;
  ZTL: string;
  Express12: string;
  IdentPIN: string;
  LocalitaSaturdayExpress: string;
  GiorniPercorso: string;
  SaturdayExpressEffettuabile: string;
};

export type CheckAddressSuggestion = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export type CheckAddressOrderPayload = {
  order_id: number;
  carrier_name: string;
  carrier_id: number;
  address: {
    street1: string;
    street2: string | null;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  from_address: {
    country: string;
  };
};

export type CheckAddressOrderResult = {
  order_id: number;
  result: string;
  messages: string[];
  /**
   * // TODO: suggestion not yet supported
   * Actually only returned from GLS as an array of array of objects
   */
  suggestions: {
    Indirizzo: string;
    Cap: string;
    Comune: string;
    SiglaProvincia: string;
  }[][];
  new_address: CheckAddressSuggestion;
  accuracy: number;
};

export enum CheckAddressProviders {
  'google' = 'Google',
}

export type OrderCheckAddressQueryPayload = {
  addresses: CheckAddressOrderPayload[];
  providers: CheckAddressProviders[];
};
export type OrderCheckAddressQueryResult = CheckAddressOrderResult[];
