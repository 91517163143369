import React from 'react';
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { User as SentryUser } from '@sentry/browser';
import * as Sentry from '@sentry/react';

import { isProductionEnv, isTestingEnv } from '@web/utils/functions/session';

import type { User } from '@web/types/global';

export const initSentry = () => {
  if (!isTestingEnv()) {
    Sentry.init({
      dsn: process.env.NX_DSN_SENTRY,
      environment: isProductionEnv()
        ? 'production'
        : // : isTestingEnv()
          // ? 'testing'
          'develop',
      release: process.env.NX_NPM_PACKAGE_VERSION,
      normalizeDepth: 10,
      ignoreErrors: [
        // just a NetworkError that sporadically occurs without breaking anything
        'LaunchDarklyFlagFetchError',
        // comes from arrangeMenu in design-system, it's harmless
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        // comes from the QZTray library, sometimes the websocket connection is closed randomly, not our fault
        'Connection closed before response received',
        "Cannot read properties of undefined (reading '0')",
        "Cannot read properties of null (reading 'established')",
        "Cannot read properties of undefined (reading 'signature')",
        // comes from the react-use-websocket library, sometimes the websocket connection is closed/reopened randomly, not our fault
        "Cannot set properties of undefined (setting 'onmessage')",
        // Lazy-loading hiccups, harmless
        /Loading CSS chunk \d+ failed\./,
        'Uncompressed Asset',
        'Large Render Blocking Asset',
        'Could not load Refiner client',
        'Could not load Userflow.js',
      ],
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.BrowserProfilingIntegration(),
        Sentry.replayIntegration(),
        Sentry.replayCanvasIntegration(),
        Sentry.metrics.metricsAggregatorIntegration(),
      ],

      // Percentage of transactions acquired
      tracesSampleRate: 0.1,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/app\.shippypro\.local\/ship/,
        /^https:\/\/app\.shippypro\.com\/ship/,
        /^https:\/\/develop\.app\.shp-dev\.com\/ship/,
      ],

      // Capture 100% of traces sessions for profiling
      profilesSampleRate: 0.1,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      attachStacktrace: true,
    });
  }
};

export const toSentryUser = (user: User): SentryUser =>
  user
    ? {
        id: user.id?.toString(),
        username: user.full_name,
        email: user.email,
      }
    : {};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
