import { FC } from 'react';
import { UncontrolledTooltip as ReactstrapUncontrolledTooltip } from 'reactstrap';

import { IUncontrolledTooltipProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <UncontrolledTooltip />` component.
 *
 * @interface IUncontrolledTooltipProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const UncontrolledTooltip: FC<IUncontrolledTooltipProps> = props => {
  const customUncontrolledTooltipProps = {
    delay: { show: 150, hide: 150 },
    ...props,
  };

  return <ReactstrapUncontrolledTooltip {...customUncontrolledTooltipProps} />;
};
