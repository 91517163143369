// ########################## [IMPORTANT LIBRARIES]
import { FC, cloneElement, memo } from 'react';
import classnames from 'classnames';

// ########################## [TYPES]
import { TabConfiguration } from '@ds-web/components/templates/with-tabs/types';

interface IRightMostContentProps {
  currentTab: TabConfiguration;
}

/**
 * [LAYOUT CARD WITH TOP TABS - RIGHT MOST CONTENT] This component is used to render the content that goes at the end
 * of the tabs listing. It can be used to render custom actions or other useful content.
 *
 * @interface IRightMostContentProps
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
const RightMostContent: FC<IRightMostContentProps> = memo(
  ({
    currentTab: {
      mainContent: { hidden },
      secondaryContent: { rightMost },
    },
  }) => (
    <div
      className={classnames(
        'md:!mx-0 gap-[10px]',
        rightMost?.wrapperClassName,
        {
          hidden,
        },
      )}
    >
      {rightMost?.render &&
        cloneElement(rightMost.render, { disabled: rightMost?.disabled })}
    </div>
  ),
);

RightMostContent.displayName = 'RightMostContent_memoized';

export default RightMostContent;
