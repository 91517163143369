// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useMemo, useState, useTransition } from 'react';

// ########################## [DESIGN SYSTEM]
import { Nav } from '@ds-web/components';

// ########################## [PAGE COMPONENTS]
import SideTabsItem from '@ds-web/components/templates/with-tabs/section-with-side-tabs/components/body/tabs-navigation/SideTabsItem';

// ########################## [TYPES]
import { ITabsListingProps } from '@ds-web/components/templates/with-tabs/types';

/**
 * [LAYOUT CARD WITH SIDE TABS - TABS LISTING] This component holds the actual tabs navigation buttons, decoupled from their content.
 *
 * @interface ITabsListingProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const SideTabsListing: FC<ITabsListingProps> = memo(
  ({ activeTabRef, tabs, activeTabIndex, toggle }) => {
    const [pending, startTransition] = useTransition();
    const [nextTab, setNextTab] = useState(0);

    // Create a lookup map for tab URLs to indices
    const tabUrlToIndex = useMemo(() => {
      return tabs.reduce(
        (acc, tab, index) => {
          if (tab.url) {
            acc[tab.url] = index;
          }
          return acc;
        },
        {} as { [url: string]: number },
      );
    }, [tabs]);

    const handleTabClick = (index: number) => {
      setNextTab(index);
      startTransition(() => toggle(index));
    };

    return (
      <Nav
        data-test="side-tabs-listing"
        type="tabs"
        className="flex-col flex-nowrap !gap-2 overflow-x-scroll lg:!overflow-visible whitespace-nowrap rounded-0"
      >
        {tabs
          .filter(t => t.tabType === 'main')
          .map(tab => {
            const trueIndex = tabUrlToIndex[tab.url];
            return (
              <SideTabsItem
                key={tab.url}
                tab={tab}
                activeTabIndex={activeTabIndex}
                activeTabRef={activeTabRef}
                toggle={handleTabClick}
                pending={pending}
                nextTab={nextTab}
                trueIndex={trueIndex}
              />
            );
          })}
      </Nav>
    );
  },
);

SideTabsListing.displayName = 'SideTabsListing_memoized';

export default SideTabsListing;
