import { KeyboardEventHandler, ReactNode, useCallback, useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import classnames from 'classnames';
import omit from 'lodash/omit';

import {
  FormGroup,
  FormGroupProps,
  Label,
  LabelProps,
  FormTextProps,
  FormFeedback,
  FormFeedbackProps,
  InputGroupText,
} from 'reactstrap';

import styled from 'styled-components';

import {
  HelperText,
  Input,
  InputGroup,
  UncontrolledTooltip,
  WarningText,
} from '@shippypro/design-system-web';
import {
  IInputProps,
  IInputGroupProps,
} from '@shippypro/design-system-web/types';

export interface ControlledInputProps<FormType extends FieldValues>
  extends Pick<
    FormGroupProps,
    | 'row'
    | 'check'
    | 'inline'
    | 'floating'
    | 'disabled'
    | 'tag'
    | 'cssModule'
    | 'className'
  > {
  name: Path<FormType>;
  type?: IInputProps['type'];
  control: Control<FormType>;
  label?: ReactNode;
  placeholder?: string;
  inputProps?: Omit<IInputProps, 'value' | 'onKeyDown'> &
    (
      | {
          onKeyDown?: IInputProps['onKeyDown'];
          forbiddenChars?: never;
        }
      | {
          onKeyDown?: never;
          forbiddenChars?: string[] | RegExp;
        }
    );
  inputGroupProps?: IInputGroupProps;
  labelProps?: LabelProps;
  errorModes?: ('formFeedback' | 'tooltip')[];
  errorTextProps?: FormFeedbackProps;
  errorText?: ReactNode;
  successTextProps?: FormFeedbackProps;
  successText?: ReactNode;
  helperTextProps?: FormTextProps;
  helperText?: ReactNode;
  warningTextProps?: FormTextProps;
  warningText?: string[];
  disabled?: boolean;
  required?: boolean;
  showCounter?: boolean;
  icon?: ReactNode;
  endIcon?: ReactNode;
  endLabelIcon?: ReactNode;
  dataTest?: string;
  inputGroupLeftSideElement?: JSX.Element | null;
  wrapperClassName?: string;
  preFillPrefix?: boolean;
  onChangeCustomAction?: () => void;
}

export function ControlledInput<FormType extends FieldValues>({
  name,
  type,
  label,
  placeholder,
  control,
  inputProps,
  inputGroupProps,
  labelProps,
  helperTextProps,
  // here we can support one or more ways to show errors: FormFeedback, tooltip...
  errorModes = ['formFeedback'],
  errorTextProps,
  errorText,
  successTextProps,
  successText,
  helperText,
  warningTextProps,
  warningText,
  className,
  disabled,
  required,
  showCounter,
  icon,
  endIcon,
  endLabelIcon,
  dataTest,
  inputGroupLeftSideElement = null,
  wrapperClassName = '',
  onChangeCustomAction,
  ...rest
}: ControlledInputProps<FormType>) {
  const isRequired = required ?? inputProps?.required;
  const inputType = type ?? inputProps?.type;

  const forbiddenChars = inputProps?.forbiddenChars;

  const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(
    e => {
      // by default, we want to treat numbers as positive integers, w/o exponential notation
      const defaultForbiddenChars =
        inputType === 'number' ? /[^0-9.]/ : undefined;

      // btw, this can be overridden via props may the need arise
      const actualForbiddenChars = forbiddenChars ?? defaultForbiddenChars;
      const isSingleChar = e.key.length === 1;

      if (actualForbiddenChars && isSingleChar) {
        const shouldPreventEvent = Array.isArray(actualForbiddenChars)
          ? (actualForbiddenChars as string[]).includes(e.key)
          : actualForbiddenChars.test(e.key);

        if (shouldPreventEvent) e.preventDefault();
      }
    },
    [inputType, forbiddenChars],
  );

  const tooltipTargetRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <div ref={tooltipTargetRef} className={wrapperClassName}>
      <Controller
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <FormGroup
            {...rest}
            className={classnames('!mb-0 w-full', className)}
          >
            <div className="flex space-x-1 label-icon-block">
              {label && (
                <Label {...labelProps}>
                  {label}
                  {isRequired && <span className="ml-[2px]">*</span>}
                </Label>
              )}
              {endLabelIcon}
            </div>
            {errorModes.includes('tooltip') && (error || errorText) && (
              <UncontrolledTooltip target={tooltipTargetRef} trigger="hover">
                {errorText ?? error?.message}
              </UncontrolledTooltip>
            )}
            <InputGroup {...inputGroupProps}>
              {icon && <InputGroupText>{icon}</InputGroupText>}
              {inputGroupLeftSideElement}
              <Input
                {...omit(inputProps, 'forbiddenChars', 'onChange', 'passedRef')}
                onBlur={inputProps?.onBlur ?? onBlur}
                onChange={e => {
                  if (inputProps?.onChange) inputProps.onChange(e);

                  onChange(e);
                  onChangeCustomAction && onChangeCustomAction();
                }}
                onWheel={e => {
                  if (e.target) {
                    try {
                      (e.target as HTMLElement).blur();
                    } catch (e) {
                      //
                    }
                  }
                }}
                defaultValue={inputProps?.defaultValue}
                placeholder={placeholder ?? inputProps?.placeholder}
                onKeyDown={inputProps?.onKeyDown ?? onKeyDown}
                value={value ?? ''}
                valid={Boolean(successText)}
                invalid={Boolean(error || errorText)}
                disabled={disabled ?? inputProps?.isDisabled}
                type={inputType}
                required={isRequired}
                className={classnames(inputProps?.className, {
                  'border border-warning': warningText,
                })}
                innerRef={inputProps?.passedRef}
                data-test={dataTest ?? name}
              />
              {showCounter && <CounterText>{value.length}</CounterText>}
              {endIcon && <InputGroupText>{endIcon}</InputGroupText>}
            </InputGroup>
            {(error || errorText) && errorModes.includes('formFeedback') && (
              <FormFeedback {...errorTextProps}>
                {errorText ?? error?.message}
              </FormFeedback>
            )}
            {successText && (
              <FormFeedback valid {...successTextProps}>
                {successText}
              </FormFeedback>
            )}
            {helperText && (
              <HelperText {...helperTextProps}>{helperText}</HelperText>
            )}
            {warningText &&
              warningText.map(text => (
                <WarningText {...warningTextProps}>{text}</WarningText>
              ))}
          </FormGroup>
        )}
        name={name}
      />
    </div>
  );
}

export function ControlledTextArea<FormType extends FieldValues>(
  props: ControlledInputProps<FormType>,
) {
  return (
    <ControlledInput
      {...props}
      type="textarea"
      inputProps={{
        rows: 5,
        ...props.inputProps,
      }}
    />
  );
}

const CounterText = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 6;
  color: var(--bs-warning);
  font-weight: bold;
  font-size: 12px;
`;
