// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';
import { NavLink, Spinner } from 'reactstrap';
import classnames from 'classnames';

// ########################## [TYPES]
import { ITabsListingProps } from '@ds-web/components/types';
import { Color } from '@ds-web/tokens/enums';

// ########################## [COMPONENTS]
import { IconHelper } from '@ds-web-iconhelper';
import TabBadge from '@ds-web/components/templates/with-tabs/components/body/tabs-navigation/TabBadge';
import ActionsMenu from '@ds-web/components/templates/with-tabs/components/body/tabs-navigation/ActionsMenu';

interface ISideTabItemProps {
  tab: ITabsListingProps['tabs'][number];
  activeTabIndex: number;
  activeTabRef: React.RefObject<HTMLDivElement> | null;
  toggle: (index: number) => void;
  pending: boolean;
  nextTab: number;
  trueIndex: number;
}

/**
 * [LAYOUT CARD WITH SIDE TABS - TABS ITEM] This component holds the actual tab item, decoupled from his content.
 * Every tab button shows a custom numeric badge and the tabs can be either a dropdown menu or a simple button.
 *
 * @interface ITabsListingProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const SideTabsItem: FC<ISideTabItemProps> = memo(
  ({
    tab,
    activeTabIndex,
    activeTabRef,
    toggle,
    pending,
    nextTab,
    trueIndex,
  }) => {
    const {
      mainContent: {
        badgeNumber = 0,
        badgeColor = Color.none,
        explainPopover,
        hidden = false,
        iconsSet = [],
        actions = [],
      },
      url,
      icon,
      title,
      titleBadge,
      disabled,
      buttonOverride,
      parentUrl,
    } = tab;

    const isTabActive = activeTabIndex === trueIndex;

    const tabId = `tab-${url}`;
    const badgeID = `${tabId}-badge`;

    return (
      <div
        key={trueIndex}
        data-test={tabId}
        id={tabId}
        className={classnames('nav-item flex', {
          'cursor-pointer': parentUrl,
          'text-lg !text-[#011627] !cursor-default': !parentUrl,
          'items-center': badgeNumber >= 0,
          '!hidden': hidden,
          'tab-disabled !cursor-not-allowed opacity-60': disabled,
        })}
        ref={isTabActive ? activeTabRef : null}
        onClick={() => {
          if (disabled) return;
          if (buttonOverride) return buttonOverride();

          toggle(trueIndex);
        }}
      >
        <NavLink
          active={isTabActive}
          badge={badgeNumber}
          className={classnames('gap-[10px] !rounded px-1', {
            '!cursor-not-allowed': disabled,
            '!text-[#011627] !cursor-default': !parentUrl,
            '!font-extrabold !bg-[#D7DFE5]': isTabActive,
          })}
        >
          {trueIndex === nextTab && pending ? (
            <Spinner size="sm" className="!mr-3" />
          ) : (
            icon && (
              <IconHelper
                icon={icon}
                stroke={isTabActive ? 2 : 1.5}
                color={isTabActive ? '#D7DFE5' : '#364959'}
                fill={isTabActive ? '#364959' : 'none'}
              />
            )
          )}
          {title}
          {titleBadge}
        </NavLink>

        <TabBadge
          badgeID={badgeID}
          badgeColor={badgeColor}
          badgeNumber={badgeNumber}
          explainPopover={explainPopover}
        />
        {iconsSet.map((tabIcon, i) => (
          <IconHelper
            key={i}
            icon={tabIcon.code}
            className={tabIcon.className}
            size={16}
          />
        ))}
        {actions?.length > 0 && (
          <ActionsMenu actions={actions} disabled={disabled} />
        )}
      </div>
    );
  },
);

SideTabsItem.displayName = 'SideTabsItem';

export default SideTabsItem;
