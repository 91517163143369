import { useMemo } from 'react';

import { MenuLink } from '@web/features/_global/types';

import useLDFlags from '@web/hooks/useLDFlags';

/**
 * Statically generated ShippyPro side menu user links
 * In the future, this should be dinamically generated from BE, maybe a Deliver-dedicated table
 */
const useSideMenuUserLinks = (): MenuLink => {
  const { settingsOnUiApp } = useLDFlags();

  const menuLink = useMemo(() => {
    return {
      data: [
        {
          label: 'accountSettings',
          link: 'settings',
          isExternal: false,
          isCustom: false,
          isVisible: settingsOnUiApp,
        },
        {
          label: 'editProfile',
          link: 'profile.html',
          isExternal: true,
          isCustom: false,
          isVisible: true,
        },
        {
          label: 'addressAndParcels',
          link: 'my-addresses-parcels.html',
          isExternal: true,
          isCustom: false,
          isVisible: true,
        },
        {
          label: 'planAndPayments',
          link: 'new-plan.html',
          isExternal: true,
          isCustom: false,
          isVisible: true,
        },
        {
          label: 'logout',
          link: 'logout',
          isExternal: false,
          isCustom: false,
          isVisible: true,
        },
      ],
    };
  }, [settingsOnUiApp]);

  return menuLink;
};

export default useSideMenuUserLinks;
