// ########################## [REDUX]
import { useSelector } from 'react-redux';
import { selectCarriersErrorMessages } from '@web/features/ship/selectors';

// ########################## [TRANSLATIONS]
import type { TFunction } from 'i18next';

// ########################## [TYPES]
import type { CarrierErrorMessageType } from '@web/features/ship/types';
import { useCallback, useMemo } from 'react';
import { translations } from '@shippypro/translations';

export const useGetErrorMessageType = () => {
  const carriersErrorMessages = useSelector(selectCarriersErrorMessages());

  const precompiledRegex = useMemo(() => {
    if (!carriersErrorMessages) return {};

    // Iterate over each carrier and its error messages to create a map with precompiled regex
    return Object.entries(carriersErrorMessages).reduce(
      (carrierRegexMap, [carrierName, errors]) => {
        carrierRegexMap[carrierName] = errors.map(error => ({
          ...error,
          regex: new RegExp(
            error.message.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'),
          ),
        }));
        return carrierRegexMap;
      },
      {},
    );
  }, [carriersErrorMessages]);

  const getErrorMessageType = useCallback(
    (
      t: TFunction<'translation', undefined>,
      message: string,
      carrierName: string | null,
    ): CarrierErrorMessageType | null => {
      // No type for not supported carriers
      if (!carrierName || !precompiledRegex[carrierName]) return null;

      // Find the matching error message in the carrier list
      const messageObj = precompiledRegex[carrierName].find(error =>
        error.regex.test(message),
      );

      // No type if we don't find a match
      if (!messageObj) return null;

      // Gets the type's translations
      const typeTransObj = messageObj.type ?? messageObj.ai_type;

      // This is a guard, in case the type is yet to be mapped :)
      /* istanbul ignore next */
      if (!typeTransObj) return null;

      const transName =
        translations.ship.carrierErrorSuggestions.types[typeTransObj.code]
          ?.name;
      const transDescription =
        translations.ship.carrierErrorSuggestions.types[typeTransObj.code]
          ?.description;

      return {
        parent: null,
        id: typeTransObj.id,
        code: typeTransObj.code,
        name: transName ? t(transName) : typeTransObj.name,
        description: transDescription
          ? t(transDescription)
          : typeTransObj.description,
      };
    },
    [precompiledRegex],
  );

  return useMemo(
    () => ({ getErrorMessageType, carriersErrorMessages }),
    [getErrorMessageType, carriersErrorMessages],
  );
};
