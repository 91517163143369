import { ElementType, FC, useMemo } from 'react';

import {
  Bar,
  BarChart as DefaultBarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IMultiBarChartDataProps } from './types';

import DefaultTooltip from '@ds-web/components/molecules/ui/charts/multi-chart-tooltip/MultiChartsTooltip';
import { toLocalizedDecimal } from '@ds-web/utils/functions';

const barsDefaults = [
  {
    type: 'monotone',
    fillOpacity: 1,
    fill: '#7349DD',
    YAxisKey: 'yValue',
    isAnimationActive: false,
    stackId: 'defaultStack',
    maxBarSize: 120,
  },
];

/**
 * A component that render a multi bar chart.
 *
 * @interface IMultiBarChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const MultiBarChart: FC<IMultiBarChartDataProps<string>> = ({
  data,
  XDataKey = 'xName',
  bars = barsDefaults,
  CustomizedAxisTick,
  CustomTooltip,
  XAxisHeight,
  YDomain,
  tickFormatter = value => toLocalizedDecimal(value, 0, 0),
}) => {
  const XAxisComponent = useMemo(
    () =>
      CustomizedAxisTick ? (
        <XAxis
          dataKey={XDataKey}
          height={XAxisHeight}
          interval={0}
          tick={<CustomizedAxisTick />}
        />
      ) : (
        <XAxis dataKey={XDataKey} />
      ),
    [CustomizedAxisTick, XAxisHeight, XDataKey],
  );

  const TooltipComponent: ElementType | FC = useMemo(
    () => CustomTooltip ?? DefaultTooltip,
    [CustomTooltip],
  );

  return (
    <ResponsiveContainer width="98%" height="100%">
      <DefaultBarChart data={data} margin={{ right: 0, left: 0 }}>
        <CartesianGrid vertical={false} />
        {XAxisComponent}
        <YAxis domain={YDomain} tickFormatter={tickFormatter} />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={data =>
            data.active && data.payload && <TooltipComponent data={data} />
          }
        />
        {bars.map((barProp, index) => {
          // If it's the last bar, set a smooth border radius
          const radius: [number, number, number, number] =
            bars.length === index + 1 ? [3, 3, 0, 0] : [0, 0, 0, 0];
          return (
            <Bar
              type={barProp.type}
              dataKey={barProp.YAxisKey as string}
              fillOpacity={barProp.fillOpacity}
              fill={barProp.fill}
              radius={radius}
              isAnimationActive={barProp.isAnimationActive}
              stackId={barProp.stackId}
              maxBarSize={barProp.maxBarSize ?? 120}
            />
          );
        })}
      </DefaultBarChart>
    </ResponsiveContainer>
  );
};
