// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useMemo, useRef } from 'react';
import classnames from 'classnames';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

// ########################## [COMMON COMPONENTS]
import { CardWrapper } from '@ds-web/components/molecules/ui/cards/card-wrapper/CardWrapper';

// ########################## [PAGE COMPONENTS]
import TabsListing from './tabs-navigation/index';
import RightMostContent from './RightMostContent';
import BodyContent from '@ds-web/components/templates/with-tabs/components/body/BodyContent';
import PreBodyContent from '@ds-web/components/templates/with-tabs/components/body/PreBodyContent';

// ########################## [HOOKS]
import { useGetDeviceSize } from '@ds-web/utils/hooks';

interface ITabsNavigatorProps extends Omit<IPageWithTabs, 'headingTitle'> {
  tabContentRef?: React.RefObject<HTMLElement>;
}

/**
 * [LAYOUT CARD WITH TOP TABS - CARD] This component is used to render the tabs navigator of the layout card.
 * It holds the actual tabs listings and their respective content.
 * N.B. The content shown is based on the currently active tab and NO OTHER page is pre-rendered and hidden in the parsed HTML code.
 *
 * @interface ITabsNavigatorProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const TabsNavigator: FC<ITabsNavigatorProps> = memo(
  ({
    tabs,
    activeTabIndex,
    toggle,
    loadInvisibleTabs,
    rightmostCTA,
    tabContentRef,
  }) => {
    const { isTablet, isMobile } = useGetDeviceSize();

    const activeTabRef = useRef<HTMLDivElement>(null);

    // Selecting the currently displayed tab based on the active "Tab Index"
    const activeTab = useMemo(
      () => tabs[activeTabIndex] ?? tabs[0],
      [activeTabIndex, tabs],
    );

    return (
      <div
        className={classnames(
          'card-top-tabs-container !w-full flex flex-col flex-nowrap h-full p-0',
          {
            'container-override': isTablet || isMobile,
          },
        )}
      >
        <section className="card-top-tabs mt-1 flex flex-[1_0_auto] flex-wrap justify-between items-end">
          <TabsListing
            activeTabRef={activeTabRef}
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            toggle={toggle}
          />
          <RightMostContent currentTab={activeTab}></RightMostContent>
          {rightmostCTA}
        </section>
        <CardWrapper
          centered={false}
          cardClassName="content-card h-full shrink-0 bg-white shadow-sm"
          bodyClassName="card-top-tabs-body h-full"
          wrapperClassName="app-card h-full flex flex-col justify-stretch"
          ref={tabContentRef}
        >
          <PreBodyContent currentTab={activeTab} />
          <BodyContent
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            loadInvisibleTabs={loadInvisibleTabs}
          />
        </CardWrapper>
      </div>
    );
  },
);

TabsNavigator.displayName = 'TabsNavigator_memoized';
